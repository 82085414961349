import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/global.scss";
import "./icon.js";
import dayjs from "dayjs";

Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.prototype.$dayJs = dayjs;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
