import { StoreOptions } from "vuex";
const userInfo: StoreOptions<any> = {
  state: {
    owner: "", // employee customer
    userInfo: {
      name: "",
    },
    organizations: {
      curOrg: {
        employee: {
          organizationId: "",
        },
      },
    },
  },

  getters: {
    getOwner(state) {
      return state.owner;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    getOrganizations(state) {
      return state.organizations;
    },
  },

  mutations: {
    SET_OWNER(state, payload) {
      state.owner = payload;
    },
    SET_USERINFO(state, payload) {
      state.userInfo = payload;
    },
    SET_ORGANIZATIONS(state, payload) {
      state.organizations = payload;
    },
  },

  actions: {
    setOwner({ commit }, val) {
      commit("SET_OWNER", val);
    },
    setUserInfo({ commit }, val) {
      commit("SET_USERINFO", val);
    },
    setOrganizations({ commit }, val) {
      commit("SET_ORGANIZATIONS", val);
    },
  },
};

export default {
  namespaced: true,
  ...userInfo,
};
