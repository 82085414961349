






import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/layout/index.vue";

@Component({
  components: {
    Layout,
  },
})
export default class App extends Vue {
  hasLayout = false;
  created(): void {
    console.log(window.location.href);
    this.hasLayout = !~window.location.href.indexOf("retrieve");
  }
}
