import http from "./axios";
import { Message } from "element-ui";

const get: (url: string, params: object, headers?: any) => any = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "get",
      url,
      params,
      headers,
    })
      .then((response) => {
        if (response.status === 200) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const post: (url: string, params: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "post",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const put: (url: string, params: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "put",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 200) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const del: (url: string, params: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "delete",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 204 || response.status === 200) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const patch: (url: string, params: object, headers?: any) => any = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    http({
      method: "patch",
      url,
      data,
      headers,
    })
      .then((response) => {
        if (response.status === 204) {
          const res = response.data;
          resolve(res);
          if (res.msg) {
            res.msg && Message.error(res.msg);
          }
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const request = {
  get,
  post,
  del,
  patch,
  put,
};

export default request;
