import { StoreOptions } from "vuex";
const organization: StoreOptions<any> = {
  state: {
    orgCount: 0,
  },

  getters: {
    getOrgCount(state) {
      return state.orgCount;
    },
  },

  mutations: {
    SET_ORG_COUNT(state, payload) {
      state.orgCount = payload;
    },
  },

  actions: {
    setOrgCount({ commit }, val) {
      commit("SET_ORG_COUNT", val);
    },
  },
};

export default {
  namespaced: true,
  ...organization,
};
