

















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteConfig, RouteRecord } from "vue-router";
import { getLoginStatus } from "@/api/auth";
import PushService from "@/pushService/index";
import { namespace } from "vuex-class";
const userinfo = namespace("userInfo");
const organization = namespace("organization");

@Component
export default class Layout extends Vue {
  @userinfo.Action("setUserInfo") setUserInfo!: any;
  @organization.Getter("getOrgCount") getOrgCount!: any;
  menus = [];

  curmenus = "organization";

  curLanguage = "ZH";

  isPanelShow = false;

  userInfo = {
    userId: "",
    name: "",
  };

  countData = {
    organization: 0,
    employee: 0,
    customer: 0,
  };

  options = [
    {
      value: "profile",
      label: "个人资料",
      icon: "user-card",
    },
    {
      value: "loginOut",
      label: "登出",
      icon: "out",
    },
  ];

  @Watch("$route", { immediate: true })
  onRouterChanged(newV: RouteRecord) {
    const strList = newV.path.split("/");
    this.curmenus = strList[2];
  }

  @Watch("getOrgCount", { immediate: true })
  onOrgCountChanged(newVal: any) {
    this.countData.organization = newVal;
  }

  // @Watch("pushServic.notifications")
  // onNotificationsChanged(newV: string) {
  //   console.log(newV);
  // }

  created(): void {
    // const pushServic = new PushService();
    // pushServic.doConnect();
    this.getUserInfo();
    const router = this.$router.options.routes as any;
    this.menus = router.find((item: any) => item.path === "/admin")?.children;
  }

  async getUserInfo() {
    try {
      const { userInfo } = await getLoginStatus();
      console.log("userInfo ===>", userInfo);
      this.userInfo.userId = userInfo.sub;
      this.userInfo.name = userInfo.name;
      this.setUserInfo(userInfo);
    } catch (error) {
      console.error(error);
    }
  }

  toPage(name: string): void {
    if (this.curmenus === name) return;
    this.curmenus = name;
    this.$router.push({ name });
  }

  handleShowPanel(): void {
    this.isPanelShow = true;
  }

  async handlePanelChange(node: string[]): Promise<void> {
    console.log(node);

    if (node[0] === "loginOut") {
      window.location.href = "/api/loginout";
    }

    if (node[0] === "profile") {
      window.open("https://myportal.abb.com/home");
    }
    this.isPanelShow = false;
  }

  handleChangeLanguage(command: string) {
    this.curLanguage = command;
    this.$message({
      message: "当前语言切换为" + command,
      type: "success",
    });
  }
}
