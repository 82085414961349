import axios from "axios";
import router from "@/router/index";

const http = axios.create({
  timeout: 20000, // 请求超时时间
  withCredentials: true,
});

http.interceptors.request.use(
  function (config: any) {
    //  设置headers头传入当前使用的语言，提供给后端判断用。
    return config;
  },
  function (error) {
    //  请求错误，关闭loading动画，返回错误。
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  //成功返回数据
  function (response) {
    //返回数据待使用
    return response.data;
  },
  //抓取数据，针对不同的错误进行不同的操作
  function (error) {
    //和后端小伙伴约定一下错误码的规则
    switch (error.response.status) {
      case 400: //请求错误
      case 401: //未授权
        router.push({ name: "404" });
        break;
      case 404: //未找到
        router.push({ path: "/inactive" });
        break;
      default:
      //页面输出错误讯息（element-ui）
      // Message.error("Oop, something went wrong. Please contact us.");
    }
    return Promise.reject(error);
  }
);

export default http;
