import request from "@/axios/http";

//  获取登陆状态和用户信息
export const getLoginStatus = () => {
  return request.get("/admin/api/loginStatus", {});
};

//  登出
export const loginout = () => {
  return request.get("/admin/api/loginout", {});
};

/**
 * Oauth2登陆
 */
export const login = () => {
  return request.get("/api/login", {});
};

// 获取用户角色
export const getUserRole = async (params: { type: string; username: string }) => {
  try {
    return await request.get("/admin/api/user/role", params);
  } catch (error) {
    return false;
  }
};

export const verifyLogin = async (params: any) => {
  return request.get("/admin/api/verify", params);
};

export const changePassword = async (params: any) => {
  try {
    return await request.put("/admin/api/password", params);
  } catch (error) {
    console.log("error", error);
    return false;
  }
};
